import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { Link } from "@ryerson/frontend.navigation";
import { Button } from "@ryerson/frontend.button";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export type BenefitsCard = {
	title: string;
	imageUrl: string;
	mobileImageUrl: string;
	benefits: string[];
	hasQuote: boolean;
	quote?: string;
	quoteAuthor?: string;
	quoteTitle?: string;
    quotePortrait?: string;
};

export type BenefitProps = {
	content: BenefitsCard;
	index: number;
};

export type BenefitsContent = {
	title: string;
	cards: BenefitsCard[];
	actionText?: string;
	actionUrl?: string;
};

export type BenefitsProps = {
	content: BenefitsContent;
};

const Divider = styled.hr`
	width: 100%;
	opacity: 0.1;
	margin-top: 10px;
	margin-bottom: 60px;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.darkerGray};
		`;
	}}
`;

const BenefitCard = styled.div`
	width: calc(50% - 40px);
	padding: 35px;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 80px;
	height: 300px;
	display: inline-block;
	vertical-align: top;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.background};
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				padding: 20px;
				margin-bottom: 0px;
				height: auto;
				display: block;
				margin-top: 1px;
				background-color: ${theme.colors.primary.darkGray};
			}
		`;
	}}
`;

const CardImage = styled.div`
	display: block;
	position: absolute;
	top: 35px;
	right: 35px;
	width: 40px;
	height: 40px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				top: 20px;
				right: 20px;
			}
		`;
	}}
`;

const QuoteCard = styled.div`
	width: 50%;
	padding-top: 35px;
	position: relative;
	box-sizing: border-box;
	height: 300px;
	margin-bottom: 80px;
	display: inline-block;
	vertical-align: top;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: transparent;
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				background-color: ${theme.colors.primary.white};
                height: auto;
                margin-bottom: 30px;
			}
		`;
	}}
`;

const QuoteAuthorContainer = styled.div`
    margin-top: 25px;
`;

const QuotePortrait = styled.div`
	display: inline-block;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	background-size: cover;
	background-position: center center;
	${(props: any) => {
		return css`
			background-image: url(${props.quotePortrait});
		`;
	}}
    margin-right: 15px;
`;

const QuoteAuthorText = styled.div`
    display: inline-block;
    position: relative;
    -webkit-transform: translateY(-10%);
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
    ${(props: any) => {
		const { theme } = props;
		return css`
            @media (max-width: ${theme.breakpoints.lg}) {
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
			}
		`;
	}}
`;

const NegSpacer = styled.div`
	width: 100%;
	height: 40px;
`;

const ButtonContainer = styled.div`
	width: 100%;
	max-width: 267px;
	margin: 0 auto;
	margin-top: 30px;
	height: auto;
	display: block;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				max-width: 300px;
				margin-top: 55px;
				margin-bottom: 70px;
			}
		`;
	}}
`;

type BenefitBullet = {
	benefits: string[];
	color?: string;
};

const BenefitBullets = (props: BenefitBullet) => {
	const { theme } = useTheme();

	return (
		<Typography variant="div" size="sm" color={props.color ?? theme.colors.primary.text}>
			<ul
				css={css`
					margin-top: 30px;
					padding-left: 15px;
				`}
			>
				{props.benefits.map((benefit: string, index: number) => {
					return <li key={index}>{benefit}</li>;
				})}
			</ul>
		</Typography>
	);
};

const Benefit: React.FC<BenefitProps> = ({ content, index }) => {
	const { theme } = useTheme();
	return (
		<>
			{index % 2 === 0 ? (
				<>
					<BenefitCard
						theme={theme}
						css={
							content.hasQuote
								? css`
										margin-right: 20px;
								  `
								: css`
										margin-right: 20px;
										width: calc(50% - 20px) !important;
										margin-bottom: 40px !important;
								  `
						}
					>
						<CardImage theme={theme}>
							<img src={content.imageUrl} />
						</CardImage>
						<Typography size="lg" color={theme.colors.primary.header} weight="bold">
							{content.title}
						</Typography>
						<BenefitBullets benefits={content.benefits} />
					</BenefitCard>
					{content.hasQuote ? (
						<QuoteCard
							theme={theme}
							css={css`
								padding-left: 100px;
							`}
						>
							<Typography
								css={css`
									letter-spacing: -2px;
									margin: 0;
									position: relative;
								`}
								variant="h4"
								color={theme.colors.primary.header}
							>
								<span
									css={css`
										position: absolute;
										left: -15px;
										top: 0;
										font-size: 30px;
										color: ${theme.colors.primary.darkGray};
									`}
								>
									“
								</span>
								{content.quote}
								<span
									css={css`
										font-size: 30px;
										margin-left: 12px;
										color: ${theme.colors.primary.darkGray};
									`}
								>
									”
								</span>
							</Typography>
                            <QuoteAuthorContainer>
                                <QuotePortrait {...content} />
                                <QuoteAuthorText theme={theme}>
                                    <Typography
                                        size="sm"
                                        css={css`
                                            display: block;
                                        `}
                                        color={theme.colors.primary.header}
                                        weight="bold"
                                    >
                                        {content.quoteAuthor}
                                    </Typography>
                                    <Typography size="sm" color={theme.colors.primary.gray}>
                                        {content.quoteTitle}
                                    </Typography>
                                </QuoteAuthorText>
                            </QuoteAuthorContainer>
							
						</QuoteCard>
					) : (
						<></>
					)}
				</>
			) : (
				<>
					{content.hasQuote ? (
						<QuoteCard
							theme={theme}
							css={css`
								padding-right: 100px;
							`}
						>
							<Typography
								css={css`
									letter-spacing: -2px;
									margin: 0;
									position: relative;
								`}
								variant="h4"
								color={theme.colors.primary.header}
							>
								<span
									css={css`
										position: absolute;
										left: -15px;
										top: 0;
										font-size: 30px;
										color: ${theme.colors.primary.lightGray};
									`}
								>
									“
								</span>
								{content.quote}
								<span
									css={css`
										font-size: 30px;
										margin-left: 12px;
										color: ${theme.colors.primary.lightGray};
									`}
								>
									”
								</span>
							</Typography>
                            <QuoteAuthorContainer>
                                <QuotePortrait {...content} />
                                <QuoteAuthorText theme={theme}>
                                    <Typography
                                        size="sm"
                                        css={css`
                                            display: block;
                                        `}
                                        color={theme.colors.primary.header}
                                        weight="bold"
                                    >
                                        {content.quoteAuthor}
                                    </Typography>
                                    <Typography size="sm" color={theme.colors.primary.gray}>
                                        {content.quoteTitle}
                                    </Typography>
                                </QuoteAuthorText>
                            </QuoteAuthorContainer>
						</QuoteCard>
					) : (
						<></>
					)}
					<BenefitCard
						theme={theme}
						css={
							content.hasQuote
								? css`
										margin-left: 20px;
								  `
								: css`
										margin-left: 20px;
										width: calc(50% - 20px) !important;
										margin-bottom: 40px !important;
								  `
						}
					>
						<CardImage theme={theme}>
							<img src={content.imageUrl} />
						</CardImage>
						<Typography size="lg" color={theme.colors.primary.header} weight="bold">
							{content.title}
						</Typography>
						<BenefitBullets benefits={content.benefits} />
					</BenefitCard>
				</>
			)}
		</>
	);
};

const Benefits: React.FC<BenefitsProps> = ({ content }) => {
	const { theme } = useTheme();

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="secondary">
					<Typography
						variant="h2"
						css={css`
							margin-top: 30px;
							margin-bottom: 40px;
						`}
						type="primary"
					>
						{content.title}
					</Typography>
					<Divider theme={theme} />
					{content.cards.map((card: BenefitsCard, index: number) => {
						return <Benefit key={index} content={card} index={index} />;
					})}
					{content.cards[0].hasQuote ? (
						<NegSpacer />
					) : (
						<ButtonContainer theme={theme}>
							<Link to={content.actionUrl ?? "#"}>
								<Button
									label={content.actionText ?? ""}
									align="center"
									shape="rounded"
									fullwidth={true}
									onClick={() => {}}
									background={theme.colors.primary.primaryBrand}
									foreground="light"
								/>
							</Link>
						</ButtonContainer>
					)}
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" hPadding="0px" vPadding="40px">
					{content.cards.map((card: BenefitsCard, index: number) => {
						return (
							<>
								<BenefitCard theme={theme}>
									<CardImage theme={theme}>
										<img src={card.mobileImageUrl} />
									</CardImage>
									<Typography
										size="lg"
										font="primary"
										color={theme.colors.primary.white}
										weight="bold"
									>
										{card.title}
									</Typography>
									<BenefitBullets
										color={theme.colors.primary.white}
										benefits={card.benefits}
									/>
								</BenefitCard>
								{card.hasQuote ? (
									<QuoteCard theme={theme}>
										<Typography
											size="xl"
											weight="bold"
											css={css`
												position: relative;
											`}
											color={theme.colors.primary.header}
                                            variant="h3"
										>
											<span
												css={css`
													position: absolute;
													// left: -10px;
													top: 0;
												`}
											>
												“
											</span>
                                            <div
                                                css={css`
                                                    margin-left: 10px;
                                            `}>

                                                    {card.quote}

                                                <span
                                                    css={css`
                                                        margin-left: 6px;
                                                    `}
                                                >
                                                    ”
                                                </span>
                                            </div>
										</Typography>
                                        <QuoteAuthorContainer>
                                            <QuotePortrait 
                                                {...card} 
                                                css={css`
                                                    width: 60px;
                                                    height: 60px;
                                                    border-radius: 60px;
                                                `}
                                            />
                                            <QuoteAuthorText theme={theme}>
                                                <Typography
                                                    size="sm"
                                                    css={css`
                                                        display: block;
                                                    `}
                                                    color={theme.colors.primary.header}
                                                    weight="bold"
                                                >
                                                    {card.quoteAuthor}
                                                </Typography>
                                                <Typography size="sm" color={theme.colors.primary.gray}>
                                                    {card.quoteTitle}
                                                </Typography>
                                            </QuoteAuthorText>
                                        </QuoteAuthorContainer>                                        
										{/* <Typography
											size="sm"
											css={css`
												margin-top: 15px;
												display: block;
											`}
											color={theme.colors.primary.header}
										>
											{card.quoteAuthor}
										</Typography>
										<Typography
											size="sm"
											color={theme.colors.primary.darkGray}
										>
											{card.quoteTitle}
										</Typography> */}
									</QuoteCard>
								) : (
									<></>
								)}
							</>
						);
					})}
					{!content.cards[0].hasQuote ? (
						<ButtonContainer theme={theme}>
							<Link to={content.actionUrl ?? "#"}>
								<Button
									label={content.actionText ?? ""}
									align="center"
									shape="rounded"
									fullwidth={true}
									onClick={() => {}}
									background={theme.colors.primary.primaryBrand}
									foreground="light"
								/>
							</Link>
						</ButtonContainer>
					) : (
						<></>
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default Benefits;
